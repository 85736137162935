import { useEffect, useState } from "react";
import { listenToAnswer } from "../../services/InteractiveAnswerService";
import { socket } from "../../config/network";
import {
  augmentedReality,
  chatbot,
  digitalBanner,
  digitalReg,
  games,
  interactiveFloor,
  interactiveUsher,
  photoBooth,
  robot,
  survey,
  spinner,
  video360,
} from "../../assets";
import { Box, Typography } from "@mui/material";

const images = [
  { media: augmentedReality, title: "Augmented Reality" },
  { media: chatbot, title: "Chatbot" },
  { media: digitalBanner, title: "Digital Banner" },
  { media: digitalReg, title: "Digital Registration" },
  { media: games, title: "Games" },
  { media: interactiveFloor, title: "Interactive Floor" },
  { media: interactiveUsher, title: "Interactive Usher" },
  { media: photoBooth, title: "Photo booth" },
  { media: robot, title: "Robot" },
  { media: survey, title: "Smart Survey" },
  { media: spinner, title: "Spinner" },
  { media: video360, title: "360 Video booth" },
];

const Features = () => {
  const [answer, setAnswer] = useState();
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    socket.auth = {
      token: localStorage.getItem("token"),
    };
    socket.connect();
  }, []);

  useEffect(() => {
    listenToAnswer((data) => {
      const res = JSON.parse(data);
      !isNaN(Number(res?.answer)) && setAnswer(Number(res?.answer));
    });
    return () => {
      socket.off("webhook_image_uuid");
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(
      () => {
        setCurrentImage((prev) => (prev === images?.length - 1 ? 0 : prev + 1));
        answer !== undefined && setAnswer();
      },
      answer ? 6000 : 2000
    );
    return () => clearInterval(interval);
  }, [answer]);

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: -1,
      }}
    >
      {images?.map((image, i) => (
        <Box
          sx={{
            width: 1,
            height: 1,
            display:
              i !== (answer !== undefined ? answer : currentImage) && "none",
          }}
          key={i}
        >
          <img
            src={image?.media}
            alt=""
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
          <Typography
            fontWeight="bold"
            variant="h6"
            sx={{
              textAlign: "center",
              position: "absolute",
              bottom: "10%",
              right: "50%",
              transform: "translate(50%, 0%)",
              backgroundColor: "#000000d9",
              color: "#fff",
              py: 2,
              px: 3,
              borderRadius: "8px",
            }}
          >
            {image?.title}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Features;
