import React, { useState } from "react";
import { TextField, Button, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Setting = () => {
  const [token, setToken] = useState("");
  const [submittedToken, setSubmittedToken] = useState(
    localStorage.getItem("token")
  );
  const nav = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    // Here you can handle the token, e.g., save it in local storage
    localStorage.setItem("token", token);
    setSubmittedToken(token);
    nav("/receiver");
  };

  return (
    <Box>
      <Button
        variant="contained"
        sx={{ alignSelf: "center", my: 2 }}
        onClick={() => nav(-1)}
      >
        <Typography color={"#fff"}>Back</Typography>
      </Button>
      {submittedToken && (
        <Box sx={{ border: "1px solid #000", width: "100%" }}>
          <Typography
            variant="subtitle2"
            sx={{
              overflowWrap: "break-word",
              whiteSpace: "normal",
            }}
          >
            Submitted Token: {submittedToken}
          </Typography>
        </Box>
      )}
      <form onSubmit={handleSubmit}>
        <TextField
          required
          fullWidth
          label="Token"
          margin="dense"
          name="token"
          value={token}
          onChange={(e) => setToken(e.target.value)}
        />
        <Button
          type="submit"
          variant="contained"
          sx={{ alignSelf: "center", marginTop: 2 }}
        >
          <Typography color={"#fff"}>Save Token</Typography>
        </Button>
      </form>
    </Box>
  );
};

export default Setting;
