import React from "react";
import Home from "./pages/home";
import Record from "./pages/record";
import VoiceChat from "./components/voiceChat";
// routing
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Features from "./pages/SocketSelect/Features";
import Setting from "./pages/SocketSelect/Setting";
import VoiceChatManual from "./components/VoiceChatManual";

const appRouter = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Home />} />
      <Route path="/record" element={<Record />} />
      <Route path="/gpt" element={<VoiceChat />} />
      <Route path="/gpt-manual" element={<VoiceChatManual />} />
      <Route path="/features" element={<Features />} />
      <Route path="/setting" element={<Setting />} />
    </>
  )
);
function App() {
  return <RouterProvider router={appRouter} />;
}

export default App;
