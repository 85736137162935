import { useState } from "react";
const Record = () => {
  const [transcription, setTranscription] = useState("");
  const [transcript, setTranscript] = useState("");
  const [isListening, setIsListening] = useState(false);

  const handleTranscription = async (audioBlob) => {
    try {
      const audioContext = new AudioContext();
      const audioBuffer = await audioContext.decodeAudioData(audioBlob);
      const recognizer = new window.webkitSpeechRecognition();
      recognizer.lang = "ar-EG";
      recognizer.interimResults = false;
      recognizer.maxAlternatives = 1;

      recognizer.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        console.log(transcript);
        setTranscription(transcript);
      };

      recognizer.onerror = (event) => {
        console.error("Recognition error:", event.error);
      };

      recognizer.onend = () => {
        console.log("Recognition ended");
      };

      const source = audioContext.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContext.destination);
      recognizer.start();
      source.start();
    } catch (error) {
      console.error("Error transcribing audio:", error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const audioBlob = e.target.result;
        handleTranscription(audioBlob);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleSpeechRecognition = () => {
    const recognition = new window.webkitSpeechRecognition(); 
    recognition.lang = "ar-EG";

    recognition.onstart = () => {
      setIsListening(true);
    };

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setTranscript(transcript);
    };

    recognition.onend = () => {
      setIsListening(false);
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
      setIsListening(false);
    };

    recognition.start();
  };
  return (
    <>
      <div>
        <button onClick={handleSpeechRecognition} disabled={isListening}>
          Start Listening
        </button>
        <div>{transcript}</div>
      </div>
      <div style={{ marginTop: "70px" }}>
        <input type="file" accept="audio/*" onChange={handleFileChange} />
        <div>{transcription}</div>
      </div>
    </>
  );
};

export default Record;
