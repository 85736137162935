import React, { useEffect, useState } from "react";
import { Button, LinearProgress } from "@mui/material";
import { startStreaming } from "../helpers/elevenLabs";
import "../App.css";
import ClipLoader from "react-spinners/ClipLoader";
import { sendTheAnswer } from "../services/InteractiveAnswerService";
import {
  video,
  robotEmoji1,
  robotEmoji2,
  robotEmoji3,
  robotEmoji4,
  robotEmoji5,
  robotEmoji6,
} from "../assets";
const VoiceChatManual = ({ user }) => {
  // eleven data
  const [elevenKey, setElevenKey] = useState("");
  const [gender, setGender] = useState("");
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_API_URL}/eleven/config`)
      .then((response) => response.json())
      .then(async (data) => {
        setElevenKey(data.elevenKey);
        setGender(data.readerGender);
      });
  }, []);

  // greeting user
  const [isApproved, setIsApproved] = useState(false);
  const [isLoading, setIsLoading] = useState("");
  const [isPlayingAudio, setIsPlayingAudio] = useState("");
  const [feeling, setFeeling] = useState("");

  const userGreeting = async () => {
    setIsApproved(true);
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_BASE_API_URL}/user-greeting`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userName: user?.name,
        userJob: user?.job,
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        const textExtract = data
          ?.replace(/`/g, "")
          ?.replace(/\n/g, "")
          ?.replace("json", "")
          ?.replace("reply", `"reply"`)
          ?.replace("feel", `"feel"`)
          ?.replace(`""reply""`, `"reply"`)
          ?.replace(`""feel""`, `"feel"`)
          ?.trim();
        const jsonData = JSON.parse(textExtract);
        data = jsonData?.reply;

        setFeeling(jsonData?.feel);
        setGptAnswer(data);
        setSpeech("  ");
        const audio = await startStreaming(data, elevenKey, gender);
        await audio.play();
        audio.addEventListener("timeupdate", () => {
          console.log("playing");
          setIsPlayingAudio(true);
        });
        audio.onended = () => {
          console.log("done playing");
          setIsPlayingAudio(false);
          setGptAnswer("");
          setSpeech("");
        };

        setIsLoading(false);
        setGptAnswer(jsonData?.reply);
        await sendTheAnswer({ answer: Math.round(Math.random() * 12) });

        // setStart(true);
      })
      .catch((error) => console.error("Error:", error));
  };

  // gpt voice chat
  const [isMicOpen, setIsMicOpen] = useState(false);
  const [speech, setSpeech] = useState("");
  const [gptAnswer, setGptAnswer] = useState("");

  function startVoiceChat() {
    let recognition = new (window.SpeechRecognition ||
      window.webkitSpeechRecognition ||
      window.mozSpeechRecognition ||
      window.msSpeechRecognition)();
    recognition.lang = "ar-EG";
    recognition.start();
    recognition.onstart = () => {
      console.log("voice is on");
      setIsMicOpen(true);
    };
    recognition.onresult = function (event) {
      console.log("You said: ", event.results[0][0].transcript);
      setSpeech(event.results[0][0].transcript);
      setIsLoading(true);
      setIsPlayingAudio(true);
      fetch(`${process.env.REACT_APP_BASE_API_URL}/gpt-chat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message: event.results[0][0].transcript,
        }),
      })
        .then((response) => response.json())
        .then(async (data) => {
          const audio = await startStreaming(data, elevenKey, gender);
          await audio.play();

          setGptAnswer(data);
          setIsLoading(false);
          audio.onended = () => {
            console.log("done playing");
            setIsPlayingAudio(false);
            setGptAnswer("");
          };
        })
        .catch((error) => console.error("Error:", error));
    };
    recognition.onend = () => {
      console.log("mic is closed");
      setIsMicOpen(false);
    };
  }

  // Countdown
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count > 0) {
      const timer = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [count]);

  return (
    <>
      {!isApproved && (
        <div className="overlay">
          <div className="overlay-content">
            <h2>Please give us permission to access mic</h2>
            <button style={{ cursor: "pointer" }} onClick={userGreeting}>
              <p>Grant Permission</p>
            </button>
          </div>
        </div>
      )}
      {isApproved && (
        <div className="video-background">
          <Button
            variant="contained"
            sx={{ position: "fixed", top: 20, left: 20, zIndex: 2 }}
            onClick={startVoiceChat}
            disabled={isPlayingAudio || isLoading}
          >
            Ask Me
          </Button>
          <video
            key={
              {
                sad: "robotEmoji2",
                normal: "robotEmoji1",
                dissapointed: "robotEmoji3",
                angry: "robotEmoji5",
                happy: "robotEmoji6",
                lovely: "robotEmoji4",
              }[feeling] || "video"
            }
            autoPlay
            loop
            muted
          >
            <source
              src={
                {
                  sad: robotEmoji2,
                  normal: robotEmoji1,
                  dissapointed: robotEmoji3,
                  angry: robotEmoji5,
                  happy: robotEmoji6,
                  lovely: robotEmoji4,
                }[feeling] || video
              }
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <div className="content">
            <div className="sweet-loading">
              <ClipLoader
                color="blue"
                loading={isLoading}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
            {speech && gptAnswer && isPlayingAudio && (
              <>
                <div className="text">
                  <h2 style={{ color: "white" }}>{speech}:</h2>
                  <h3 style={{ color: "white" }}>{gptAnswer}</h3>
                </div>
              </>
            )}
            <div>
              {count !== 0 ? (
                <h1
                  style={{
                    color: "white",
                    position: "fixed",
                    top: "60%",
                    right: "50%",
                    transform: "translate(50%, 50%)",
                  }}
                >
                  Countdown: {count}
                </h1>
              ) : null}
            </div>
            {isMicOpen && !isPlayingAudio ? (
              <>
                <div className="mic">
                  <img
                    src={require("../assets/mic.png")}
                    width="100"
                    height="100%"
                    alt="mic"
                  />
                  <div>
                    <h1 style={{ color: "white" }}>يمكنك التحدث الان</h1>
                    <LinearProgress
                      style={{
                        width: "100%",
                        height: "15px",
                      }}
                    />
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default VoiceChatManual;
